<template>
  <div>
    <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
      <span class="ant-pro-account-avatar">
        <a-avatar size="small" :src="option.img || 'https://static.jnzbtz.com/default_avatar.png'" class="antd-pro-global-header-index-avatar" />
        <span>{{ corName + '--' + currentUser.name }}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <!--        <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          个人中心
        </a-menu-item>-->
          <a-menu-item v-if="menu" key="settings" @click="handleToSettings">
            <a-icon type="setting" />
            个人设置
          </a-menu-item>
          <a-menu-item v-if="menu" key="center" @click="showChangeCorporation">
            <a-icon type="retweet" />
            更换公司
          </a-menu-item>
          <a-menu-divider v-if="menu" />
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout" />
            退出登录
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
    <a-modal v-model="visible" title="更换企业" @ok="corporok">
      <a-select style="width: 80%; margin: 0 auto" @change="handleChange">
        <a-select-option v-for="item in corlist" :key="item.id" :value="item.id">
          {{ item.corporation.name }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { getInfo } from '@/api/login'
import store from '@/store'
import { user_list } from '@/api/user'
import storage from 'store'
import { User_Id } from '@/store/mutation-types'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null
    },
    menu: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      visible: false,
      corlist: [],
      selectCorporation: {},
      option: {
        img: ''
      }
    }
  },
  created () {
    this.userId = storage.get(User_Id)
    this.getuserlist()
    this.getInfo()
  },
  computed: {
    corName: function () {
      let corporation = JSON.parse(JSON.parse(localStorage.getItem('corporation')))
      console.log(corporation, '更换公司更换公司名称')
      return corporation.name
    }
  },
  methods: {
    // 更换企业
    getInfo () {
      const userId = storage.get(User_Id)
      console.log(userId, '我是啥')
      getInfo({ user_id: userId }).then(({ data }) => {
        this.corlist = data.entries
        console.log(this.corlist)
      })
    },
    handleChange (value) {
      console.log(`selected ${value}`)
      this.selectCorporation = this.corlist.find((item) => {
        return item.id === value
      })
      console.log(this.selectCorporation, '当前公司')
    },
    corporok () {
      const corporation = this.selectCorporation.corporation
      store.dispatch('ChangeCorporation', { corporation }).then((res) => {
        this.$router.push({ path: '/' })
        this.$router.go(0)
      })
      this.visible = false
    },
    showChangeCorporation () {
      this.visible = true
    },
    getuserlist () {
      user_list({ id: this.userId }).then(({ data }) => {
        this.option.img = data.entries[0].avatar
      })
    },
    handleToCenter () {
      this.$router.push({ path: '/account/center' })
    },
    handleToSettings () {
      this.$router.push({ path: '/account/settings' })
    },
    handleLogout (e) {
      Modal.confirm({
        title: this.$t('layouts.usermenu.dialog.title'),
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>

import storage from 'store'
import { login, getInfo } from '@/api/login'
import { ACCESS_TOKEN, User_Id, CORPORATION, USERNAME, IsPower, CORPORATION_Id } from '@/store/mutation-types'
import { Dictionaries } from '@/common/AllConstant'
import { welcome } from '@/utils/util'
// eslint-disable-next-line no-unused-vars
import jwt_decode from 'jwt-decode'
import { user_permission_list } from '@/api/user'
import { corporation_config_list } from '@/api/corporation'
const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    corporation: {},
    corporation_id: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_CORPORATION: (state, corporation) => {
      state.corporation = corporation
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          console.log(response, '返回值', jwt_decode(response.jwt))
          // 用户信息
          const result = response
          const userobj = result.user
          const AC_TOKEN = result.jwt
          const userId = jwt_decode(AC_TOKEN).user_id || 404
          storage.set(ACCESS_TOKEN, AC_TOKEN, 7 * 24 * 60 * 60 * 1000)
          storage.set(User_Id, userId, 2 * 24 * 60 * 60 * 1000)
          storage.set(USERNAME, userobj.nickname, 2 * 24 * 60 * 60 * 1000)
          localStorage.userInfo = JSON.stringify(userobj)
          commit('SET_INFO', userobj)
          commit('SET_TOKEN', AC_TOKEN)
          commit('SET_NAME', { name: userobj.nickname, welcome: welcome() })
          commit('SET_AVATAR', userobj.avatar)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }, obj) {
      return new Promise((resolve, reject) => {
        getInfo({ user_id: obj.userId }).then(response => {
          console.log('我是最开始的获取路由信息-----------------------', obj.corporationId)
          const result = response.data
          let corporation_id = null
          if (obj.corporationId) {
            corporation_id = obj.corporationId
          } else {
            corporation_id = response.data.entries[0].corporation.id
          }
          console.log(result.entries)
          const currentcorporation = result.entries.find((item) => {
            return corporation_id === item.corporation.id
          })
          console.log(currentcorporation, '我是更换后的公司')
          storage.set(CORPORATION, JSON.stringify(currentcorporation.corporation))
          commit('SET_CORPORATION', currentcorporation.corporation)
          Dictionaries.corporation_id = corporation_id
          corporation_config_list(corporation_id).then(({ data }) => {
            storage.set(IsPower, JSON.stringify(data), 7 * 24 * 60 * 60 * 1000)
          })
          user_permission_list({ corporation_id: corporation_id }).then(({ data }) => {
            // 权限信息
            const resultData = data
            const ogrole = {}
            const view_jsonlist = resultData.map((item) => {
              return JSON.parse(item.view_json)
            })
            ogrole.permissions = view_jsonlist.flat()
            if (ogrole && ogrole.permissions.length > 0) {
              const role = ogrole
              role.permissions = ogrole.permissions
              role.permissions.map(per => {
                if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                  const action = per.actionEntitySet.map(action => { return action.defaultCheck ? action.action : '' })
                  per.actionList = action
                }
              })
              role.permissionList = role.permissions.map(permission => { return permission.permissionId })
              resultData[0].permissionList = role.permissionList
              const userInfo = JSON.parse(localStorage.userInfo)
              commit('SET_ROLES', ogrole.permissions)
              commit('SET_INFO', userInfo)
              resolve(resultData[0])
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }
          })
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    ChangeInfo ({ commit }, obj) {
      return new Promise((resolve, reject) => {
        getInfo({ user_id: obj.userId }).then(({ data }) => {
          console.log(obj.corporationId, '我是后来的获取信息')
          storage.set(CORPORATION, JSON.stringify(obj.curreCorporation))
          commit('SET_CORPORATION', obj.curreCorporation)
          const corporation_id = obj.corporationId
          Dictionaries.corporation_id = corporation_id
          corporation_config_list(corporation_id).then(({ data }) => {
            console.log('IsPower', data)
            storage.set(IsPower, JSON.stringify(data), 7 * 24 * 60 * 60 * 1000)
          })
          user_permission_list({ corporation_id: corporation_id }).then(({ data }) => {
            // 权限信息
            const resultData = data
            const ogrole = {}
            const view_jsonlist = resultData.map((item) => {
              return JSON.parse(item.view_json)
            })
            console.log(view_jsonlist, '5895')
            ogrole.permissions = view_jsonlist.flat()
            console.log(ogrole.permissions, '权限列表')
            if (ogrole && ogrole.permissions.length > 0) {
              const role = ogrole
              role.permissions = ogrole.permissions
              role.permissions.map(per => {
                if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
                  const action = per.actionEntitySet.map(action => { return action.action })
                  per.actionList = action
                }
              })
              role.permissionList = role.permissions.map(permission => { return permission.permissionId })
              resultData[0].permissionList = role.permissionList
              const userInfo = JSON.parse(localStorage.userInfo)
              commit('SET_ROLES', ogrole.permissions)
              commit('SET_INFO', userInfo)
              resolve(resultData[0])
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }
          })
        }).catch(error => {
          console.log(error)
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit }, state) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        storage.remove(ACCESS_TOKEN)
        window.localStorage.clear()
        resolve()

        /* logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        }) */
      })
    },

    // 更换公司
    ChangeCorporation ({ commit }, { corporation }) {
        return new Promise((resolve) => {
          storage.set(CORPORATION_Id, corporation.id)
          resolve()
        })
    }
  }
}

export default user

export let Dictionaries = {
  TakeInventoryCommodityStatus: {
    1: '多余',
    2: '缺失'
  },
  NoticeType: {
    1: '保质期提醒'
  },
  ExhibitionCommodityStatus: {
    1: '未处理',
    2: '已处理'
  },
  ExhibitionReturnMode: {
    1: '退回原处',
    2: '退货区'
  },
  ExhibitionRefundMode: {
    1: '全部退款',
    2: '部分退款'
  },
  InspurBatchInsertType: {
    1: '运输',
    2: '消费'
  },
  OrderStatus: {
    1: '已创建',
    2: '已支付',
    3: '已取消',
    4: '已退款',
    5: '部分退款'
  },
  TakeInventoryStatus: {
    1: '已创建',
    2: '已完成',
    3: '已取消'
  },
  InspurReceiptStatus: {
    0: '错误',
    1: '待审批',
    2: '审批通过',
    3: '拒绝',
    4: '已领取'
  },
  WarehouseTransferDirection: {
    1: '出区',
    2: '入区'
  },
  ExhibitionWarehouseCategory: {
    1: '摊位',
    2: '超市'
  },
  PayTool: {
    1: '其他',
    2: '现金',
    3: '支付宝',
    4: '微信',
    5: '银联',
    6: '网银',
    7: '网页支付',
    8: '银行卡支付',
    9: '快捷支付'
  },
  SelfLifeUnit: {
    1: '天',
    2: '月',
    3: '年'
  },
  WarehouseTransferStatus: {
    1: '创建',
    2: '处理',
    3: '完成'
  },
  BondedCommodityStatus: {
    1: '创建',
    2: '完成'
  },
  WarehouseSubTransferStatus: {
    1: '冻结',
    2: '出库',
    3: '入库'
  },
  BondedCommodityChangeCategory: {
    1: '入库',
    2: '调拨',
    3: '展览',
    4: '销售',
    5: '盘存',
    6: '退款'
  },
  Currency: {
    1: 'USD',
    2: 'EUR',
    3: 'JPY',
    4: 'HKD',
    5: 'GBP',
    6: 'CNY'
  },
  TemporaryCarAction: {
    1: '南门进区',
    2: '南门出区',
    3: '西门进区',
    4: '西门出区'
  },
  status: [
    { 1: '待入园' },
    { 2: '已入园' },
    { 3: '已注销' }
  ],
  RecordAction: {
    1: '创建',
    2: '删除'
  },
  corporation_id: '',
  CorporationLocation: {
    1: '北',
    2: '南',
    3: '其他'
  },
  location: [
    { 1: '北区' },
    { 2: '南区' },
    { 3: '其他' }
  ],
  rolesObj: {
    1: '管理员',
    2: '企业',
    3: '物业',
    4: '协管'
  },
  inout: {
    3: '进区',
    4: '出区'
  },
  roles: [
    { 1: '管理员' },
    { 2: '企业' },
    { 3: '物业' },
    { 4: '协管' }
  ],
  ContractStatus: {
    1: '已创建',
    2: '已签约',
    3: '已废除'
  },
  BillStatus: {
    1: '待支付',
    2: '已支付',
    3: '已取消',
    4: '已完成',
    6: '未生成审批',
    7: '审批中',
    8: '审批失败'
  },
  BillSearchStatus: {
    1: '待支付',
    2: '已支付',
    3: '已取消'
  },
  PaymentTicketStatus: {
    1: '未审核',
    2: '已审核',
    3: '申请发票',
    4: '已完成',
    5: '已拒绝'
  },
  FapiaoApplicationStatus: {
    1: '待审核',
    2: '已同意',
    3: '已拒绝'
  },
  PaymentTicketCategory: {
    1: '物业',
    2: '租金',
    3: '水费',
    4: '电费',
    5: '预付电费',
    6: '保证金',
    10: '系统生成',
    11: '手工生成'
  },
  GateWayItem2: [
    {
      key: 1,
      label: '固定资产类',
      value: 1,
      children: [
        {
          label: '请选择',
          value: '',
          key: ''
        },
        {
          label: '仪器、工具、机器/设备',
          value: 1,
          key: 1
        },
        {
          label: '办公类',
          value: 2,
          key: 2
        }
      ]
    },
    {
      key: 2,
      value: 2,
      label: '消耗品类',
      children: [
        {
          label: '请选择',
          value: '',
          key: ''
        },
        {
          label: '自用办公耗材',
          value: 11,
          key: 11
        },
        {
          label: '生产用物料',
          value: 12,
          key: 12
        },
        {
          label: '劳保',
          value: 13,
          key: 13
        },
        {
          label: '生活消费',
          value: 14,
          key: 14
        }
      ]
    },
    {
      key: 3,
      value: 3,
      label: '基建设备物品类',
      children: [
        {
          label: '请选择',
          value: '',
          key: ''
        },
        {
          label: '机器',
          value: 21,
          key: 21
        },
        {
          label: '设备',
          value: 22,
          key: 22
        },
        {
          label: '物资',
          value: 23,
          key: 23
        }
      ]
    },
    {
      key: 5,
      value: 5,
      label: '渣土',
      children: [
        {
          label: '请选择',
          value: '',
          key: ''
        },
        {
          label: '渣土',
          value: 41,
          key: 41
        }
      ]
    },
    {
      key: 4,
      value: 4,
      label: '其他',
      children: [
        {
          label: '请选择',
          value: '',
          key: ''
        },
        {
          label: '其他',
          value: 31,
          key: 31
        }
      ]
    }
  ],
  rent_contract: [
    { en_name: 'corporation', name: '公司' },
    { en_name: 'start_date', name: '开始时间' },
    { en_name: 'end_date', name: '结束时间' },
    { en_name: 'deposit', name: '押金' },
    { en_name: 'year_increase', name: '年增长百分比' },
    { en_name: 'number', name: '合同编号' },
    { en_name: 'address', name: '企业注册地址' },
    { en_name: 'rent_address', name: '企业租赁地址' },
    { en_name: 'in_charge_person', name: '负责人' },
    { en_name: 'contact_person', name: '乙方联系人' },
    { en_name: 'contact_phone', name: '乙方联系人电话' },
    { en_name: 'a_contact_person', name: '甲方联系人' },
    { en_name: 'a_contact_phone', name: '甲方联系人电话' }
  ],
  GateWayStatus: {
    1: '已创建',
    2: '已核准',
    3: '已进区',
    4: '已出区',
    5: '已取消'
  },
  GateWayItem: [
    {
      key: 1,
      title: '固定资产类',
      value: 1,
      children: [
        {
          title: '仪器、工具、机器/设备',
          value: 1,
          key: 1
        },
        {
          title: '办公类',
          value: 2,
          key: 2
        }
      ]
    },
    {
      key: 2,
      value: 2,
      title: '消耗品类',
      children: [
        {
          title: '自用办公耗材',
          value: 11,
          key: 11
        },
        {
          title: '生产用物料',
          value: 12,
          key: 12
        },
        {
          title: '劳保',
          value: 13,
          key: 13
        },
        {
          title: '生活消费',
          value: 14,
          key: 14
        }
      ]
    },
    {
      key: 3,
      value: 3,
      title: '基建设备物品类',
      children: [
        {
          title: '机器',
          value: 21,
          key: 21
        },
        {
          title: '设备',
          value: 22,
          key: 22
        },
        {
          title: '物资',
          value: 23,
          key: 23
        }
      ]
    },
    {
      key: 4,
      value: 4,
      title: '其他',
      children: [
        {
          title: '其他',
          value: 31,
          key: 31
        }
      ]
    }
  ],
  Category: {
    1: '固定资产类',
    2: '消耗品类',
    3: '基建设备物品类',
    4: '其他',
    5: '渣土'
  },
  Subcategory: {
    1: '仪器、工具、机器/设备',
    2: '办公类',
    11: '自用办公耗材',
    12: '生产用物料',
    13: '劳保',
    14: '生活消费',
    21: '机器',
    22: '设备',
    23: '物资',
    31: '其他',
    41: '渣土'
  },
  returnCode: {
    1000: '成功请求并返回数据',
    2000: '参数不足',
    2001: '参数验证失败',
    3000: '数据异常',
    4000: '对象不存在',
    4001: '用户名重复',
    4002: '用户名或密码错误',
    4003: '状态错误',
    4004: '有子资源',
    4005: '数据重复',
    4006: '获取微信 openid 错误',
    4007: '解密数据失败',
    4008: '第三方服务错误',
    4009: '密码错误',
    4010: '账单已存在',
    4011: '配置项没有打开',
    4012: '库存不足',
    4013: '数量限制',
    4014: '记录存在',
    4015: '微信错误',
    4016: '微信凭证错误',
    4017: '展示商品金额错误',
    4018: '展示商品退回路径错误',
    4019: '商品计算库存错误',
    4020: '商品重复退款',
    4021: '等待浪潮审批',
    4022: '浪潮驳回',
    4023: '无配置项',
    4024: '验证码过期',
    4025: '验证码错误',
    4026: '验证码重复请求',
    4027: '微信小程序设置昵称检测错误',
    4028: '微信小程序设置昵称设置错误',
    4029: '微信小程序设置昵称步骤中新增临时素材错误',
    4031: '微信小程序设置昵称步骤中新增临时素材错误',
    4032: '微信小程序收货地址未设置',
    4033: '微信小程序代码上传失败',
    4034: '微信小程序代码提交审核失败',
    4035: '微信小程序设置简介失败',
    4036: '微信小程序设置隐私失败',
    4037: '微信小程序发布失败',
    4038: '微信小程序回退失败',
    4039: '微信用户未登录',
    4040: '仓位未删除',
    4041: '仓位有商品未删除',
    4042: '商品有入库记录无法删除',
    4043: '申报失败',
    4044: '未实名认证'
  },
  unit: {
    '001':	'台',
    '002':	'座',
    '003':	'辆',
    '004':	'艘',
    '005':	'架',
    '006':	'套',
    '007':	'个',
    '008':	'只',
    '009':	'头',
    '010':	'张',
    '011':	'件',
    '012':	'支',
    '013':	'枝',
    '014':	'根',
    '015':	'条',
    '016':	'把',
    '017':	'块',
    '018':	'卷',
    '019':	'副',
    '020':	'片',
    '021':	'组',
    '022':	'份',
    '023':	'幅',
    '025':	'双',
    '026':	'对',
    '027':	'棵',
    '028':	'株',
    '029':	'井',
    '030':	'米',
    '031':	'盘',
    '032':	'平方米',
    '033':	'立方米',
    '034':	'筒',
    '035':	'千克',
    '036':	'克',
    '037':	'盆',
    '038':	'万个',
    '039':	'具',
    '040':	'百副',
    '041':	'百支',
    '042':	'百把',
    '043':	'百个',
    '044':	'百片',
    '045':	'刀',
    '046':	'疋',
    '047':	'公担',
    '048':	'扇',
    '049':	'百枝',
    '050':	'千只',
    '051':	'千块',
    '052':	'千盒',
    '053':	'千枝',
    '054':	'千个',
    '055':	'亿支',
    '056':	'亿个',
    '057':	'万套',
    '058':	'千张',
    '059':	'万张',
    '060':	'千伏安',
    '061':	'千瓦',
    '062':	'千瓦时',
    '063':	'千升',
    '067':	'英尺',
    '070':	'吨',
    '071':	'长吨',
    '072':	'短吨',
    '073':	'司马担',
    '074':	'司马斤',
    '075':	'斤',
    '076':	'磅',
    '077':	'担',
    '078':	'英担',
    '079':	'短担',
    '080':	'两',
    '081':	'市担',
    '083':	'盎司',
    '084':	'克拉',
    '085':	'市尺',
    '086':	'码',
    '088':	'英寸',
    '089':	'寸',
    '095':	'升',
    '096':	'毫升',
    '097':	'英加仑',
    '098':	'美加仑',
    '099':	'立方英尺',
    '101':	'立方尺',
    '110':	'平方码',
    '111':	'平方英尺',
    '112':	'平方尺',
    '115':	'英制马力',
    '116':	'公制马力',
    '118':	'令',
    '120':	'箱',
    '121':	'批',
    '122':	'罐',
    '123':	'桶',
    '124':	'扎',
    '125':	'包',
    '126':	'箩',
    '127':	'打',
    '128':	'筐',
    '129':	'罗',
    '130':	'匹',
    '131':	'册',
    '132':	'本',
    '133':	'发',
    '134':	'枚',
    '135':	'捆',
    '136':	'袋',
    '139':	'粒',
    '140':	'盒',
    '141':	'合',
    '142':	'瓶',
    '143':	'千支',
    '144':	'万双',
    '145':	'万粒',
    '146':	'千粒',
    '147':	'千米',
    '148':	'千英尺',
    '149':	'百万贝可',
    '163':	'部',
    '164':	'亿株'
  },
  country: {
    '101': '阿富汗',
    '102': '巴林',
    '103': '孟加拉国',
    '104': '不丹',
    '105': '文莱',
    '106': '缅甸',
    '107': '柬埔寨',
    '108': '塞浦路斯',
    '109': '朝鲜',
    '110': '香港',
    '111': '印度',
    '112': '印度尼西亚',
    '113': '伊朗',
    '114': '伊拉克',
    '115': '以色列',
    '116': '日本',
    '117': '约旦',
    '118': '科威特',
    '119': '老挝',
    '120': '黎巴嫩',
    '121': '澳门',
    '122': '马来西亚',
    '123': '马尔代夫',
    '124': '蒙古',
    '125': '尼泊尔联邦民主共和国',
    '126': '阿曼',
    '127': '巴基斯坦',
    '128': '巴勒斯坦',
    '129': '菲律宾',
    '130': '卡塔尔',
    '131': '沙特阿拉伯',
    '132': '新加坡',
    '133': '韩国',
    '134': '斯里兰卡',
    '135': '叙利亚',
    '136': '泰国',
    '137': '土耳其',
    '138': '阿联酋',
    '139': '也门',
    '141': '越南',
    '142': '中国',
    '143': '台澎金马关税区',
    '144': '东帝汶',
    '145': '哈萨克斯坦',
    '146': '吉尔吉斯斯坦',
    '147': '塔吉克斯坦',
    '148': '土库曼斯坦',
    '149': '乌兹别克斯坦',
    '199': '亚洲其他国家(地区)',
    '201': '阿尔及利亚',
    '202': '安哥拉',
    '203': '贝宁',
    '204': '博茨瓦纳',
    '205': '布隆迪',
    '206': '喀麦隆',
    '207': '加那利群岛',
    '208': '佛得角',
    '209': '中非',
    '210': '塞卜泰(休达)',
    '211': '乍得',
    '212': '科摩罗',
    '213': '刚果(布)',
    '214': '吉布提',
    '215': '埃及',
    '216': '赤道几内亚',
    '217': '埃塞俄比亚',
    '218': '加蓬',
    '219': '冈比亚',
    '220': '加纳',
    '221': '几内亚',
    '222': '几内亚比绍',
    '223': '科特迪瓦',
    '224': '肯尼亚',
    '225': '利比里亚',
    '226': '利比亚',
    '227': '马达加斯加',
    '228': '马拉维',
    '229': '马里',
    '230': '毛里塔尼亚',
    '231': '毛里求斯',
    '232': '摩洛哥',
    '233': '莫桑比克',
    '234': '纳米比亚',
    '235': '尼日尔',
    '236': '尼日利亚',
    '237': '留尼汪',
    '238': '卢旺达',
    '239': '圣多美和普林西比',
    '240': '塞内加尔',
    '241': '塞舌尔',
    '242': '塞拉利昂',
    '243': '索马里',
    '244': '南非',
    '245': '西撒哈拉',
    '246': '苏丹',
    '247': '坦桑尼亚',
    '248': '多哥',
    '249': '突尼斯',
    '250': '乌干达',
    '251': '布基纳法索',
    '252': '刚果(金)',
    '253': '赞比亚',
    '254': '津巴布韦',
    '255': '莱索托',
    '256': '梅利利亚',
    '257': '斯威士兰',
    '258': '厄立特里亚',
    '259': '马约特',
    '260': '南苏丹共和国',
    '299': '非洲其他国家(地区)',
    '301': '比利时',
    '302': '丹麦',
    '303': '英国',
    '304': '德国',
    '305': '法国',
    '306': '爱尔兰',
    '307': '意大利',
    '308': '卢森堡',
    '309': '荷兰',
    '310': '希腊',
    '311': '葡萄牙',
    '312': '西班牙',
    '313': '阿尔巴尼亚',
    '314': '安道尔',
    '315': '奥地利',
    '316': '保加利亚',
    '318': '芬兰',
    '320': '直布罗陀',
    '321': '匈牙利',
    '322': '冰岛',
    '323': '列支敦士登',
    '324': '马耳他',
    '325': '摩纳哥',
    '326': '挪威',
    '327': '波兰',
    '328': '罗马尼亚',
    '329': '圣马力诺',
    '330': '瑞典',
    '331': '瑞士',
    '334': '爱沙尼亚',
    '335': '拉脱维亚',
    '336': '立陶宛',
    '337': '格鲁吉亚',
    '338': '亚美尼亚',
    '339': '阿塞拜疆',
    '340': '白俄罗斯',
    '343': '摩尔多瓦',
    '344': '俄罗斯联邦',
    '347': '乌克兰',
    '349': '塞尔维亚和黑山',
    '350': '斯洛文尼亚',
    '351': '克罗地亚',
    '352': '捷克',
    '353': '斯洛伐克',
    '354': '北马其顿',
    '355': '波黑',
    '356': '梵蒂冈城国',
    '357': '法罗群岛',
    '358': '塞尔维亚',
    '359': '黑山',
    '399': '欧洲其他国家(地区)',
    '401': '安提瓜和巴布达',
    '402': '阿根廷',
    '403': '阿鲁巴',
    '404': '巴哈马',
    '405': '巴巴多斯',
    '406': '伯利兹',
    '408': '多民族玻利维亚国',
    '409': '博内尔',
    '410': '巴西',
    '411': '开曼群岛',
    '412': '智利',
    '413': '哥伦比亚',
    '414': '多米尼克',
    '415': '哥斯达黎加',
    '416': '古巴',
    '417': '库腊索岛',
    '418': '多米尼加共和国',
    '419': '厄瓜多尔',
    '420': '法属圭亚那',
    '421': '格林纳达',
    '422': '瓜德罗普',
    '423': '危地马拉',
    '424': '圭亚那',
    '425': '海地',
    '426': '洪都拉斯',
    '427': '牙买加',
    '428': '马提尼克',
    '429': '墨西哥',
    '430': '蒙特塞拉特',
    '431': '尼加拉瓜',
    '432': '巴拿马',
    '433': '巴拉圭',
    '434': '秘鲁',
    '435': '波多黎各',
    '436': '萨巴',
    '437': '圣卢西亚',
    '438': '圣马丁岛',
    '439': '圣文森特和格林纳丁斯',
    '440': '萨尔瓦多',
    '441': '苏里南',
    '442': '特立尼达和多巴哥',
    '443': '特克斯和凯科斯群岛',
    '444': '乌拉圭',
    '445': '委内瑞拉',
    '446': '英属维尔京群岛',
    '447': '圣其茨和尼维斯',
    '448': '圣皮埃尔和密克隆',
    '449': '荷属安的列斯',
    '499': '拉丁美洲其他国家(地区)',
    '501': '加拿大',
    '502': '美国',
    '503': '格陵兰',
    '504': '百慕大',
    '599': '北美洲其他国家(地区)',
    '601': '澳大利亚',
    '602': '库克群岛',
    '603': '斐济',
    '604': '盖比群岛',
    '605': '马克萨斯群岛',
    '606': '瑙鲁',
    '607': '新喀里多尼亚',
    '608': '瓦努阿图',
    '609': '新西兰',
    '610': '诺福克岛',
    '611': '巴布亚新几内亚',
    '612': '社会群岛',
    '613': '所罗门群岛',
    '614': '汤加',
    '615': '土阿莫土群岛',
    '616': '土布艾群岛',
    '617': '萨摩亚',
    '618': '基里巴斯',
    '619': '图瓦卢',
    '620': '密克罗尼西亚联邦',
    '621': '马绍尔群岛',
    '622': '帕劳',
    '623': '法属波利尼西亚',
    '625': '瓦利斯和浮图纳',
    '699': '大洋洲其他国家(地区)',
    '701': '国(地)别不详',
    '702': '联合国及机构和国际组织'
  },
  CustomsPaymentCheckStatus: {
    1: '已创建',
    2: '已完成',
    3: '失败'
  },
  is_pushed: {
    0: '已推送',
    1: '未推送'
  },
  is_auto_pushed: {
    1: '是',
    0: '否'
  },
  can_pushed: {
    1: '是',
    0: '否'
  },
  mallshop_order_status: {
    1: '待支付',
    2: '待发货',
    3: '待收货',
    4: '待评价',
    5: '已取消',
    6: '系统取消',
    7: '退款中',
    8: '已退款',
    9: '已支付，库存不足',
    10: '待审核',
    11: '部分退款'
  },
  wx_register_status: {
    UNREGISTERED: 1, // 未注册
    WAITINGLEGALPERSON: 2, // 上送资料成功，待法人通过刷脸
    REGISTERFAILED: 3, // 上送资料失败
    TOADDNICKNAME: 4, // 需要设置名称
    ADDINGNICKNAME: 5, // 设置昵称中
    NICKNAMEFAILED: 6, // 设置昵称失败
    TOSETAVATOR: 7, // 需要设置头像
    AVATORFAILED: 8, // 设置头像失败
    COMPLETED: 9 // 快速注册完成
  },
  merchants_status: {
    REJECT: 1, // 驳回
    WAITING: 2, // 未进件
    TOBESIGNED: 3, // 等待法人
    EXAMINING: 4, // 审核中
    OPENINGPERMISSION: 5, // 开放权限中
    DONE: 6 // 完成
  },
  export_order_status: {
    1: '待推送',
    2: '已推送',
    3: '已取消'
  },
  order_return_status: {
    '1': '电子口岸已暂存',
    '2': '电子口岸申报中',
    '3': '发送海关成功',
    '4': '发送海关失败',
    '100': '海关退单',
    '120': '海关入库',
    '300': '人工审核',
    '399': '海关审结',
    '800': '放行',
    '899': '结关',
    '500': '查验',
    '501': '扣留移送通关',
    '502': '扣留移送缉私',
    '503': '扣留移送法规',
    '599': '其它扣留',
    '700': '退运'
  },
  shelf_life_unit: {
    1: '天',
    2: '月',
    3: '年'
  },
  commodity_purchase_status: {
    1: '创建',
    2: '完成'
  },
  trans_mode: {
   '0': '非保税区',
   '1': '监管仓库',
   '2': '水路运输',
   '3': '铁路运输',
   '4': '公路运输',
   '5': '航空运输',
   '6': '邮件运输',
   '7': '保税区',
    '8': '保税仓库',
   '9': '其它运输',
   'A': '全部运输方式',
   'H': '边境特殊海关作业区',
   'W': '物流中心',
   'X': '物流园区',
   'Y': '保税港区',
   'Z': '出口加工区'
  },
  pick_status: {
    1: '未指定',
    2: '已指定',
    3: '已分拣',
    4: '已打包',
    5: '海关审查中',
    6: '已通过'
  },
  pick_logistics: {
    1: '申通'
  },
  order_exam_status: {
    2: '通过',
    5: '不通过'
  },
  deliveryMethod: {
    1: '自提',
    2: '快递'
  },
  property_owner: {
    1: '86611778101421004588',
    2: '86611778101421004571',
    3: '86611029101421026547',
    4: '86611731101421004169'
  },
  electricity_meter_property_owner: {
    1: '自营',
    2: '管委会',
    3: '济高'
  },
  corporation_permission_group: {
    'in_out': '进出区管理',
    'contract': '合同',
    'bill': '账单',
    'finance': '财务',
    'basic': '基础',
    'user': '用户',
    'bonded': '保税',
    'show': '展示',
    'customs_declaration': '报关',
    'config': '配置',
    'null': '其他'
  }
}

/* eslint-disable */
import { UserLayout, BasicLayout, BlankLayout, PageView } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [
  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '主页' },
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/Workplace',
        component: PageView,
        meta: { title: '概览', keepAlive: true, icon: 'dashboard' },
        children: [
          {
            path: '/dashboard/workplace',
            name: 'Workplace',
            component: () => import('@/views/dashboard/Workplace'),
            meta: { title: '快捷导航', keepAlive: true }
          }
        ]
      },
      // car
      {
        path: '/car',
        name: 'car',
        redirect: '/car/CarInformation',
        component: PageView,
        meta: { title: '进出区管理', keepAlive: true, icon: 'car', permission: [ 'administration', 'inout_application', 'inout_book', 'temporary' ] },
        children: [
          {
            path: '/car/CarInformation',
            name: 'CarInformation',
            component: () => import('@/views/a-car/CarInformation'),
            meta: { title: '授权行政车辆', keepAlive: false, permission: [ 'administration' ] }
          },
          {
            path: '/car/CarInformation/:id',
            name: 'CarInformationDetail',
            hidden: true,
            component: () => import('@/views/a-car/table/Read'),
            meta: { title: '授权行政车辆', keepAlive: false }
          },
          {
            path: '/car/Quick',
            name: 'InOut',
            component: () => import('@/views/a-inout/InOut'),
            meta: { title: '便捷出入区申请单', keepAlive: false, permission: [ 'inout_application' ] }
          },
          {
            path: '/car/Quick/:id',
            name: 'InOutDetail',
            hidden: true,
            component: () => import('@/views/a-inout/table/Look'),
            meta: { title: '便捷出入区申请单', keepAlive: false }
          },
          {
            path: '/car/book',
            name: 'Book',
            component: () => import('@/views/a-inout/Book'),
            meta: { title: '便捷出入区台账', keepAlive: false, permission: [ 'inout_book' ] }
          },
          {
            path: '/car/temporary',
            name: 'Temporary',
            component: () => import('@/views/a-inout/Temporary'),
            meta: { title: '入区登记', keepAlive: false, permission: [ 'temporary' ] }
          }
          /*,
          {
            path: '/car/GatewayApplicationInformation',
            name: 'GatewayApplicationInformation',
            component: () => import('@/views/a-car/GatewayApplicationInformation'),
            meta: { title: '授权货车申请', keepAlive: false, permission: [ 'gateway' ] }
          }*/
        ]
      },
      {
        path: '/tag',
        name: 'tag',
        redirect: '/tag/TagInformation',
        component: PageView,
        meta: { title: '标签', keepAlive: true, icon: 'tags', permission: [ 'corporation_tags' ] },
        children: [
          {
            path: '/tag/TagInformation',
            name: 'TagInformation',
            component: () => import('@/views/a-tag/TagInformation'),
            meta: { title: '标签', keepAlive: false, permission: [ 'corporation_tags' ] }
          }
        ]
      },
      // a-contract
      {
        path: '/contract',
        name: 'contract',
        redirect: '/contract/ContractInformation',
        component: PageView,
        meta: { title: '合同', keepAlive: true, icon: 'file', permission: [ 'contract_information' ] },
        children: [
          {
            path: '/contract/ContractInformation',
            name: 'ContractInformation',
            component: () => import('@/views/a-rent_contract/RentContract'),
            meta: { title: '合同信息', keepAlive: false, permission: [ 'contract_information' ] }
          }
        ]
      },
      {
        path: '/bill',
        name: 'bill',
        redirect: '/water/index',
        component: PageView,
        meta: { title: '账单', keepAlive: true, icon: 'account-book', permission: [ 'water_fees', 'electricity_fees', 'property_fees', 'rent_fees', 'guarantee_fees' ] },
        children: [
          {
            path: '/water/index',
            name: 'Water',
            component: () => import('@/views/a-water/Water'),
            meta: { title: '水费账单', keepAlive: false, permission: [ 'water_fees' ] }
          },
          {
            path: '/water_record/:id',
            name: 'WaterRecord',
            hidden: true,
            component: () => import('@/views/a-water/table/WaterRecordDetailList'),
            meta: { title: '账单记录', keepAlive: false, permission: [ 'water_fees' ] }
          },
          {
            path: '/electricity_prepay/index',
            name: 'ElectricityPrepay',
            component: () => import('@/views/a-electricity_prepay/ElectricityPrepay'),
            meta: { title: '预付电费账单', keepAlive: false, permission: ['electricity_fees']}
          },
          {
            path: '/electricity/index',
            name: 'Electricity',
            component: () => import('@/views/a-electricity/Electricity'),
            meta: { title: '电费账单', keepAlive: false, permission: ['electricity_fees'] }
          },
          {
            path: '/guarantee/index',
            name: 'Guarantee',
            component: () => import('@/views/a-guarantee/Guarantee'),
            meta: { title: '保证金账单', keepAlive: false, permission: ['guarantee_fees'] }
          },
          {
            path: '/electricity_record/:id',
            name: 'ElectricityRecord',
            hidden: true,
            component: () => import('@/views/a-electricity/table/ElectricityDetailRecord'),
            meta: { title: '账单记录', keepAlive: false, permission: ['electricity_fees'] }
          },
          {
            path: '/electricity_meter/:id',
            name: 'ElectricityMeterSingle',
            hidden: true,
            component: () => import('@/views/a-electricity/table/SingleElectricityMeter'),
            meta: { title: '电表余额', keepAlive: false, permission: ['electricity_meter_balance']  }
          },
          {
            path: '/property_bill/index',
            name: 'PropertyBill',
            component: () => import('@/views/a-property_bill/PropertyBill'),
            meta: { title: '物业账单', keepAlive: false, permission: [ 'property_fees' ] }
          },
          {
            path: '/rent_bill/index',
            name: 'RentBill',
            component: () => import('@/views/a-rent_bill/RentBill'),
            meta: { title: '租金账单', keepAlive: false, permission: [ 'rent_fees' ] }
          }
        ]
      },
      {
        path: '/finance',
        name: 'finance',
        redirect: '/fapiao_application/index',
        component: PageView,
        meta: { title: '财务', keepAlive: true, icon: 'bank', permission: [ 'bank_account' ] },
        children: [
          // {
          //   path: '/fapiao_application/index',
          //   name: 'FapiaoApplication',
          //   component: () => import('@/views/a-fapiao_application/FapiaoApplication'),
          //   meta: { title: '发票申请记录', keepAlive: false, permission: [ 'fapiao_application' ] }
          // },
          // {
          //   path: '/fapiao_application/bills',
          //   name: 'FapiaoApplicationBill',
          //   hidden: true,
          //   component: () => import('@/views/a-payment_ticket/table/BillList'),
          //   meta: { title: '待发票申请', keepAlive: false, permission: [ 'fapiao_application' ] }
          // },
          // {
          //   path: '/fapiao_application/bill',
          //   name: 'PaymentTicket',
          //   component: () => import('@/views/a-payment_ticket/PaymentTicket'),
          //   meta: { title: '发票申请', keepAlive: false, permission: [ 'fapiao_information' ] }
          // },
          {
            path: '/fapiao/FaPiaoInformation',
            name: 'FaPiaoInformation',
            component: () => import('@/views/a-fapiao/FaPiaoInformation'),
            meta: { title: '发票信息', keepAlive: false, permission: [ 'fapiao_information' ] }
          },
          {
            path: '/property_balance/index',
            name: 'PropertyBalance',
            component: () => import('@/views/a-property_balance/table/List'),
            meta: { title: '资产余额', keepAlive: false }
          },
          {
            path: '/bank_account/index',
            name: 'BankAccount',
            component: () => import('@/views/b-bank-account/BankAccount'),
            meta: { title: '银行账户', keepAlive: false, permission: ['bank_account'] }
          }
        ]
      },
// jichu
      {
        path: '/basic',
        name: 'basic',
        redirect: '/inout/undertaking',
        component: PageView,
        meta: { title: '基础', keepAlive: true, icon: 'table', permission: [ 'undertaking'] },
        children: [
          {
            path: '/inout/undertaking',
            name: 'Undertaking',
            component: () => import('@/views/a-inout/Undertaking'),
            meta: { title: '承诺书', keepAlive: false, permission: [ 'undertaking' ] }
          }
        ]
      },
      // user
      {
        path: '/user',
        name: 'user',
        redirect: '/user/UserInformation',
        component: PageView,
        meta: { title: '用户', keepAlive: true, icon: 'user', permission: [ 'user_information', 'role' ] },
        children: [
          {
            path: '/user/UserInformation',
            name: 'UserInformation',
            component: () => import('@/views/a-user/UserInformation'),
            meta: { title: '用户信息', keepAlive: false, permission: [ 'user_information' ] }
          },
          {
            path: '/user/UserInformation/:id',
            name: 'UserInformationEdit',
            hidden: true,
            component: () => import('@/views/a-user/table/Edit'),
            meta: { title: '用户信息编辑', keepAlive: false, permission: [ 'user_information' ] }
          },
          {
            path: '/role/RoleInformation',
            name: 'RoleList',
            component: () => import('@/views/a-role/RoleInformation'),
            meta: { title: '权限组配置', keepAlive: false, permission: [ 'role' ] }
          },
          {
            path: '/role/RoleInformation/:id',
            name: 'RoleListEdit',
            hidden: true,
            component: () => import('@/views/a-role/table/Edit'),
            meta: { title: '权限组配置编辑', keepAlive: false, permission: [ 'role' ] }
          }
        ]
      },
      // 保税
      {
        path: '/bonded',
        name: 'bonded',
        redirect: '/bonded/warehouse',
        component: PageView,
        meta: { title: '保税', keepAlive: true, icon: 'barcode', permission: [ 'bonded_warehouse', 'bonded_goods', 'bonded_commodity', 'bonded_purchase', 'bonded_out_purchase', 'bonded_transfer', '' ] },
        children: [
          {
            path: '/bonded/warehouse',
            name: 'BondedWarehouse',
            component: () => import('@/views/b-bonded_warehouse/BondedWarehouse'),
            meta: { title: '保税仓库', keepAlive: false, permission: [ 'bonded_warehouse' ] }
          },
          {
            path: '/bonded/warehouse/:id',
            name: 'BondedWarehouseEdit',
            hidden: true,
            component: () => import('@/views/b-bonded_warehouse/table/Edit'),
            meta: { title: '保税仓库编辑', keepAlive: false, permission: [ 'bonded_warehouse' ] }
          },
          {
            path: '/bonded/goods',
            name: 'BondedGoods',
            component: () => import('@/views/b-bonded_goods/BondedGoods'),
            meta: { title: '保税货物', keepAlive: false, permission: [ 'bonded_goods' ] }
          },
          {
            path: '/bonded/goods/:id',
            name: 'BondedGoodsEdit',
            hidden: true,
            component: () => import('@/views/b-bonded_goods/table/Edit'),
            meta: { title: '保税货物编辑', keepAlive: false, permission: [ 'bonded_goods' ] }
          },
          {
            path: '/bonded/category',
            name: 'BondedGoodsCategory',
            component: () => import('@/views/b-bonded_goods_category/BondedGoodsCategory'),
            meta: { title: '保税货物分类', keepAlive: false }
          },
          {
            path: '/bonded/goods/commodity',
            name: 'BondedWarehouseGoods',
            component: () => import('@/views/b-bonded_warehouse/BondedWarehouseGoods'),
            meta: { title: '保税商品库存', keepAlive: false, permission: [ 'bonded_commodity' ] }
          },
          {
            path: '/bonded_goods/purchase/commodity/:id?/goods/:goods_id?',
            name: 'BondedGoodsPurchase',
            component: () => import('@/views/b-bonded_goods/BondedGoodsPurchase'),
            meta: { title: '保税商品入库', keepAlive: false, permission: [ 'bonded_purchase' ]}
          },
          {
            path: '/bonded_goods/purchase/out/commodity/:id?/goods/:goods_id?',
            name: 'BondedGoodsOutPurchase',
            component: () => import('@/views/b-bonded_goods/BondedGoodsOutPurchase'),
            meta: { title: '保税商品出库', keepAlive: false, permission: [ 'bonded_out_purchase' ]}
          },
          {
            path: '/bonded_warehouse',
            name: 'BondedWarehouseTransfer',
            component: RouteView,
            redirect: '/bonded_warehouse/transfer',
            meta: { title: '保税区内调拨', keepAlive: false, permission: [ 'bonded_transfer' ] },
            children: [
              {
                path: '/bonded_warehouse/transfer',
                name: 'BondedWarehouseTransfer',
                component: () => import('@/views/b-bonded_warehouse_transfer/BondedWarehouseTransfer'),
                meta: { title: '调拨单', keepAlive: false, permission: [ 'bonded_transfer' ] },
              },
              {
                path: '/bonded_warehouse/transfer/subtranser',
                name: 'BondedWarehouseSubTransfer',
                component: () => import('@/views/b-bonded_warehouse_transfer/BondedWarehouseSubTransfer'),
                meta: { title: '调拨子单', keepAlive: false, permission: [ 'bonded_transfer' ] }
              }
            ]
          },
          {
            path: '/bonded_goods/batch/:id',
            name: 'BondedGoodsBatch',
            hidden: true,
            component: () => import('@/views/b-bonded_goods/BondedGoodsBatch'),
            meta: { title: '保税商品', keepAlive: false}
          },
          {
            path: '/bonded_goods/attachment/:id',
            name: 'attachment',
            hidden: true,
            component: () => import('@/views/b-bonded_goods/BondedGoodsAttachment'),
            meta: { title: '附件', keepAlive: false}
          },
          {
            path: '/bonded/warehouse/detail/:id',
            name: 'WarehouseDetail',
            hidden: true,
            component: () => import('@/views/b-bonded_warehouse/BondedWarehouseDetail'),
            meta: { title: '库位', keepAlive: false}
          }
        ]
      },
      //展示
      {
        path: '/exhibition',
        name: 'exhibition',
        redirect: '/exhibition/goods',
        component: PageView,
        meta: { title: '展示', keepAlive: true, icon: 'dollar', permission: [ 'exhibition_warehouse' ] },
        children: [
          {
            path: '/exhibition/warehouse',
            name: 'ExhibitionWarehouse',
            component: () => import('@/views/b-exhibition_warehouse/ExhibitionWarehouse'),
            meta: { title: '展示仓库', keepAlive: false, permission: [ 'exhibition_warehouse' ] }
          },
          {
            path: '/exhibition/warehouse/:id',
            name: 'ExhibitionWarehouseEdit',
            hidden: true,
            component: () => import('@/views/b-exhibition_warehouse/table/Edit'),
            meta: { title: '展示仓库编辑', keepAlive: false, permission: [ 'exhibition_warehouse' ] }
          },
          {
            path: '/exhibition/goods',
            name: 'ExhibitionGoods',
            component: () => import('@/views/b-exhibition_goods/ExhibitionGoods'),
            meta: { title: '展示货物', keepAlive: false, permission: [ 'exhibition_goods' ] }
          },
          {
            path: '/exhibition/goods/:id',
            name: 'ExhibitionGoodsEdit',
            hidden: true,
            component: () => import('@/views/b-exhibition_goods/table/Edit'),
            meta: { title: '展示货物编辑', keepAlive: false, permission: [ 'exhibition_goods' ] }
          },
          {
            path: '/exhibition/commodity',
            name: 'ExhibitionCommodity',
            component: RouteView,
            redirect: '/exhibition/commodity/index',
            meta: { title: '展示商品', keepAlive: false, permission: [ 'exhibition_commodity' ] },
            children: [
              {
                path: '/exhibition/commodity/index',
                name: 'ExhibitionCommodity',
                component: () => import('@/views/b-exhibition_commodity/ExhibitionCommodity'),
                meta: { title: '商品', keepAlive: false, permission: [ 'exhibition_commodity' ] }
              },
              {
                path: '/exhibition/commodity/index/:id',
                name: 'ExhibitionCommodityEdit',
                hidden: true,
                component: () => import('@/views/b-exhibition_commodity/table/Edit'),
                meta: { title: '商品编辑', keepAlive: false, permission: [ 'exhibition_commodity' ] }
              },
              {
                path: '/exhibition/commodity/qid/:id',
                name: 'qid',
                hidden: true,
                component: () => import('@/views/b-exhibition_commodity/ExhibitionQid'),
                meta: { title: 'QID', keepAlive: false}
              },
              {
                path: '/exhibition/commodity/qid/record/:id',
                name: 'qid_record',
                hidden: true,
                component: () => import('@/views/b-exhibition_commodity/ExhibitionCommodityQIDRecord'),
                meta: { title: 'QID记录', keepAlive: false}
              }
            ]
          },
          {
            path: '/exhibition/commodity/record/:id',
            name: 'ExhibitionCommodityRecord',
            hidden: true,
            component: () => import('@/views/b-exhibition_commodity/ExhibitionCommodityRecord'),
            meta: { title: '展示商品记录', keepAlive: false, permission: [ 'exhibition_commodity' ] }
          },
          {
            path: '/exhibition_warehouse',
            name: 'ExhibitionWarehouseTransfer',
            component: RouteView,
            redirect: '/exhibition_warehouse/transfer',
            meta: { title: '商品进出区', keepAlive: false, permission: [ 'exhibition_warehouse_transfer' ] },
            children: [
              {
                path: '/exhibition_warehouse/transfer',
                name: 'ExhibitionWarehouseTransfer',
                component: () => import('@/views/b-exhibition_warehouse_transfer/BondedWarehouseTransfer'),
                meta: { title: '调拨单', keepAlive: false, permission: [ 'exhibition_warehouse_transfer' ] },
              },
              {
                path: '/exhibition_warehouse/transfer/subtranser',
                name: 'ExhibitionWarehouseSubTransfer',
                component: () => import('@/views/b-exhibition_warehouse_transfer/BondedWarehouseSubTransfer'),
                meta: { title: '调拨子单', keepAlive: false, permission: [ 'exhibition_warehouse_transfer' ] }
              }
            ]
          },
          {
            path: '/normal/commodity',
            name: 'NormalCommodity',
            component: () => import('@/views/b-normal_commodity/NormalCommodity'),
            meta: { title: '一般商品', keepAlive: false, permission: [ 'normal_commodity' ] }
          },
          {
            path: '/normal/commodity/transfer',
            name: 'NormalCommodityTransfer',
            hidden: true,
            component: () => import('@/views/b-normal_commodity/NormalCommodityTransfer'),
            meta: { title: '一般商品调拨记录', keepAlive: false}
          },
          {
            path: '/exhibition/index',
            name: 'Order',
            component: RouteView,
            redirect: '/exhibition/order',
            meta: { title: '交易订单', keepAlive: false, permission: [ 'exhibition_order' ] },
            children: [
              {
                path: '/exhibition/order',
                name: 'ExhibitionOrder',
                component: () => import('@/views/b-exhibition_order/ExhibitionOrder'),
                meta: { title: '订单', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/cash_refund',
                name: 'ExhibitionOrderCashRefund',
                component: () => import('@/views/b-exhibition_order/ExhibitionOrderCashRefund'),
                meta: { title: '现金退款', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/commodity_refund',
                name: 'CommodityRefund',
                component: () => import('@/views/b-exhibition_order/CommodityRefund'),
                meta: { title: '线上退款', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/normal/cash_refund',
                name: 'ExhibitionOrderNormalCashRefund',
                component: () => import('@/views/b-exhibition_order/NormalCashRefund'),
                meta: { title: '一般商品现金退款', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/normal/refund',
                name: 'ExhibitionOrderNormalRefund',
                component: () => import('@/views/b-exhibition_order/NormalCommodityRefund'),
                meta: { title: '一般商品线上退款', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/record_refund',
                name: 'RecordRefund',
                component: () => import('@/views/b-exhibition_order/RecordRefund'),
                meta: { title: '退款总记录', keepAlive: false, permission: [ 'exhibition_order' ] },
              },
              {
                path: '/exhibition/order/detail',
                name: 'ExhibitionOrderRecord',
                hidden: true,
                component: () => import('@/views/b-exhibition_order/ExhibitionOrderRecord'),
                meta: { title: '订单详情', keepAlive: false }
              },
            ]
          },
          {
            path: '/exhibition/deal/record',
            name: 'ExhibitionOrderDeal',
            component: () => import('@/views/b-exhibition_report/ExhibitionOrderDeal'),
            meta: { title: '展示商品成交记录', keepAlive: false}
          },
          {
            path: '/exhibition/exchange_rate',
            name: 'ExhibitionRate',
            component: () => import('@/views/b-exchange_rate/ExchangeRate'),
            meta: { title: '汇率', keepAlive: false, permission: [ 'bonded_rate' ] }
          },
          {
            path: '/inspur/qid/',
            name: 'InspurQid',
            component: () => import('@/views/b-inspur_qid_list/InspurQid'),
            meta: { title: '浪潮QID', keepAlive: false}
          },
          {
            path: '/inventory/record',
            name: 'Inventory',
            meta: { title: '盘存', keepAlive: false, permission: [ 'take_inventory' ] },
            component: RouteView,
            redirect: '/inventory',
            children: [
              {
                path: '/inventory',
                name: 'Inventory',
                component: () => import('@/views/b-inventory/Inventory'),
                meta: { title: '盘存记录', keepAlive: false},
              },
              {
                path: '/inventory/read',
                name: 'Inventory',
                hidden: true,
                component: () => import('@/views/b-inventory/InventoryRead'),
                meta: { title: '盘存详情', keepAlive: false}
              }
            ]
          },
          {
            path: '/exhibition/declaration',
            name: 'declaration',
            component: () => import('@/views/b-exhibition_declaration/ExhibitionDeclaration'),
            meta: { title: '申报单', keepAlive: false, permission: [ 'declaration' ] }
          }
        ]
      },
      // 订单
      {
        path: '/e/customs_order',
        component: PageView,
        redirect: '/e/customs_order/import',
        meta: { title: '报关', keepAlive: true,icon: 'unordered-list', permission: [ 'customs_entry_1210', 'customs_config_1210', 'export_order' ]   },
        name: 'CustomsOrder',
        children: [
          {
            path: '/e/customs_order/import',
            redirect: '/e/customs_order/import/order',
            component: RouteView,
            name: 'CustomsImport',
            meta: { title: '进口', keepAlive: true },
            children: [
              {
                path: '/e/customs_order/import/order',
                name: 'CustomsOrderList',
                component: () => import('@/views/b-customs_order/CustomsOrder'),
                meta: { title: '申请订单列表', keepAlive: true },
              },
              {
                path: '/e/customs_order/import/customs_payment_check',
                name: 'CustomsPaymentCheckList',
                component: () => import('@/views/b-customs_order/CustomsPaymentCheck'),
                meta: { title: '179支付单检查列表', keepAlive: true },
              },
              {
                path: '/e/customs_order/import/customs_inventory',
                name: 'CustomsInventory',
                component: () => import('@/views/b-customs_order/CustomsInventory'),
                meta: { title: '进口清单列表', keepAlive: true },
              },
              {
                path: '/e/customs_order/import/customs_inventory_revoke',
                name: 'CustomsInventoryRevoke',
                component: () => import('@/views/b-customs_order/CustomsInventoryCancel'),
                meta: { title: '撤销进口清单列表', keepAlive: true },
              }
            ]
          },
          {
            path: '/e/customs_order/export',
            redirect: '/e/customs_order/export/order',
            name: 'CustomsExport',
            component: RouteView,
            meta: { title: '出口', keepAlive: true, permission: [ 'export_order' ] },
            children: [
              {
                path: '/e/customs_order/export/order',
                name: 'CustomsOrderExport',
                component: () => import('@/views/b-customs_order_export/CustomsOrderExport'),
                meta: { title: '订单报文', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/order/read',
                name: 'CustomsOrderExportRead',
                component: () => import('@/views/b-customs_order_export/CustomsOrderExportRead'),
                meta: { title: '订单报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/order/:order_no/records',
                name: 'CustomsOrderExportRead',
                hidden: true,
                component: () => import('@/views/b-customs_order_export/table/StatusList'),
                meta: { title: '订单报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/receipts',
                name: 'CustomsOrderreceipts',
                component: () => import('@/views/b-customs_order_receipts/CustomsOrderExport'),
                meta: { title: '支付单报文', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/receipts/read',
                name: 'CustomsOrderreceiptsRead',
                component: () => import('@/views/b-customs_order_receipts/CustomsOrderExportRead'),
                meta: { title: '支付报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/receipts/:order_no/records',
                name: 'CustomsOrderreceiptsRecords',
                hidden: true,
                component: () => import('@/views/b-customs_order_receipts/table/StatusList'),
                meta: { title: '支付报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/inventory',
                name: 'CustomsOrderinventory',
                component: () => import('@/views/b-customs_order_inventory/CustomsOrderExport'),
                meta: { title: '清单报文', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/inventory/read',
                name: 'CustomsOrderinventoryRead',
                component: () => import('@/views/b-customs_order_inventory/CustomsOrderExportRead'),
                meta: { title: '清单报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/inventory/:order_no/records',
                name: 'CustomsOrderInventoryRecords',
                hidden: true,
                component: () => import('@/views/b-customs_order_inventory/table/StatusList'),
                meta: { title: '清单报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/waybill',
                name: 'CustomsOrderwaybill',
                component: () => import('@/views/b-customs_order_waybill/CustomsOrderExport'),
                meta: { title: '总分单报文', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/waybill/read',
                name: 'CustomsOrderwaybillRead',
                component: () => import('@/views/b-customs_order_waybill/CustomsOrderExportRead'),
                meta: { title: '总分单报文详情', keepAlive: true },
              },
              {
                path: '/e/customs_order/export/waybill/:cop_no/records',
                name: 'CustomsOrderWaybillRecords',
                hidden: true,
                component: () => import('@/views/b-customs_order_waybill/table/StatusList'),
                meta: { title: '总分单报文详情', keepAlive: true },
              },
            ]
          },
          {
            path: 'corporation_config',
            name: 'corporationConfig',
            component: () => import('@/views/b-customs_order/CorporationConfig'),
            meta: { title: '配置', keepAlive: true },
          },
          {
            path: ':order_id/goods',
            name: 'CustomsOrderGoodsList',
            hidden: true,
            component: () => import('@/views/b-customs_order/table/CustomsOrderGoodsList'),
            meta: { title: '订单商品列表', keepAlive: true }
          },
          {
            path: ':order_no/order_records',
            name: 'CustomsOrderRecordsList',
            hidden: true,
            component: () => import('@/views/b-customs_order/table/OrderReadList'),
            meta: { title: '订单状态记录', keepAlive: true }
          },
          {
            path: ':order_id/list',
            name: 'CustomsInventoryList',
            hidden: true,
            component: () => import('@/views/b-customs_order/table/CustomsInventoryList'),
            meta: { title: '订单商品列表', keepAlive: true }
          },
          {
            path: ':order_no/records',
            name: 'CustomsInventoryRecords',
            hidden: true,
            component: () => import('@/views/b-customs_order/table/InventoryReadList'),
            meta: { title: '清单状态记录', keepAlive: true }
          },
          /*  {
           path: 'customs_logistics',
           name: 'CustomsLogisticsList',
           component: () => import('@/views/b-customs_order/CustomsLogisticsList'),
           meta: { title: '申报运单列表', keepAlive: true },
         },*/
        ]
      },
      //商城管理
    //小程序注册
      {
        path: '/authorization_event_reception/wechat',
        component: PageView,
        redirect: '/authorization_event_reception/applet',
        name: 'applet',
        meta: { title: '小程序', icon: 'wechat'},
        children: [
          {
            path: '/authorization_event_reception/wechat/authorized_intiation',
            name: 'applet_register',
            component: () => import('@/views/wx-mall-apply-register/register/RegisterAppletAccount'),
            meta: { title: '注册小程序', permission: [ 'wx_applet']}
          },
          {
            path: '/authorization_event_reception/wechat/wx_pay_apply/',
            name: 'applet_apply_commit',
            component: () => import('@/views/wx-mall-apply-register/wx-applet_payapply/StepForm'),
            meta: { title: '微信支付申请', permission: [ 'wx_merchants']}
          },
          {
            path: '/authorization_event_reception/wechat/wx_applet_config/',
            name: 'wx_applet_config',
            component: () => import('@/views/wx-mall-apply-register/config/CorporationConfig'),
            meta: { title: '微信小程序企业配置', permission: [ 'wx_applet_config']}
          },
          {
            path: '/shopmall',
            name: 'shopmallManage',
            redirect: '/shopmall/goods_category',
            component: RouteView,
            meta: {
              title: '商城管理', permission: [ 'wx_manage_order'] },
              children: [
              {
                path: '/shopmall/goods_category',
                component: () => import('@/views/wx_shopmall_manage/ShopMallManage'),
                name: 'goodsCategoryList',
                meta: {
                  title: '商品类目'
                }
              },
                {
                path: '/shopmall/goods_category/:id',
                component: () => import('@/views/wx_shopmall_manage/edit'),
                name: 'goodsCategoryListEdit', hidden: true,
                meta: {
                  title: '商品类目编辑'
                }
              },
              {
                path: '/shopmall/order',
                component: () => import('@/views/wx_shopmall_manage/OrderList'),
                name: 'OrderList',
                meta: {
                  title: '订单管理',permission: [ 'wx_manage_order']
                },
              },
              {
                path: '/shopmall/order/detail',
                component: () => import('@/views/wx_shopmall_manage/OrderDetail'),
                name: 'OrderList',
                hidden: true,
                meta: {
                  title: '订单详情',permission: [ 'wx_manage_order']
                }
              },
                {
                  path: '/shopmall/order/refund',
                  component: () => import('@/views/wx_shopmall_manage/OrderRefundList'),
                  name: 'OrderRefundList',
                  meta: {
                    title: '订单退款',permission: [ 'wx_manage_order']
                  },
                }
            ]
          },
          //商品管理
          {
            path: '/wx',
            name: 'goodsManage',
            redirect: '/wx/goods/list',
            component: RouteView,
            meta: {
              title: '商品管理',
              permission: [ 'wx_merchants']
            },
            children: [
              {
                path: '/wx/goods/list',
                component: () => import('@/views/wx_mall_goods/list'),
                name: 'goodsList',
                meta: {
                  title: '商品列表',
                  permission: ['wx_merchants']
                }
              },
              {
                path: '/wx/goods/create',
                component: () => import('@/views/wx_mall_goods/create'),
                name: 'goodsCreate',
                meta: {
                  title: '商品上架',
                  permission: ['wx_merchants']
                }
              },
              {
                path: '/wx/goods/edit',
                component: () => import('@/views/wx_mall_goods/edit'),
                name: 'goodsEdit',
                meta: {
                  title: '商品编辑',
                  permission: ['wx_merchants']
                },
                hidden: true
              }/*,
          {
            path: '/wx/goods/comment',
            component: () => import('@/views/wx_mall_goods/comment'),
            name: 'goodsComment',
            meta: {
              title: '商品评论'
            }
          }*/
            ]
          }
          /*,
          {
            path: '/authorization_event_reception/wechat/template',
            name: 'applet_template',
            component: () => import('@/views/wx-mall-template/template/Template'),
            meta: { title: '模板选择'}
          },
          {
            path: '/authorization_event_reception/wechat/version',
            name: 'applet_template_publish',
            component: () => import('@/views/wx-mall-template/version/PublishVersion'),
            meta: { title: '版本发布'}
          }*/
        ]
      },
      {
        path: '/zb/wms',
        component: PageView,
        redirect: '/zb/wms/warehouse',
        name: 'wms',
        meta: { title: '库存管理', icon: 'stock', permission: [ 'wms_warehouse', 'common_warehouse_inventory', 'wms_cargo_space', 'wms_goods', 'wms_commodity', 'wms_commodity_purchase', 'wms_picks', 'wms_consumable' ] },
        children: [
          {
            path: '/zb/wms/common_warehouse',
            name: 'WmsCommonWarehouseList',
            component: () => import('@/views/c-wms/common_warehouse/WareHouse'),
            meta: { title: '公共仓库存', permission: [ 'common_warehouse_inventory' ]}
          },
          {
            path: '/zb/wms/warehouse',
            name: 'WmsWarehouseList',
            component: () => import('@/views/c-wms/warehouse/WareHouse'),
            meta: { title: '私有仓库', permission: [ 'wms_warehouse' ]}
          },
          {
            path: '/zb/wms/warehouse_edit/:id',
            name: 'WmsWarehouseListEdit',
            hidden: true,
            component: () => import('@/views/c-wms/warehouse/table/Edit'),
            meta: { title: '私有仓库编辑', permission: [ 'wms_warehouse' ]}
          },
          {
            path: '/zb/wms/warehouse/:id',
            name: 'WmsWarehouseDetail',
            hidden: true,
            component: () => import('@/views/c-wms/cargo_space/CargoSpace'),
            meta: { title: '私有仓库库位', permission: [ 'wms_cargo_space' ]}
          },
          {
            path: 'consumable',
            name: 'WmsConsumableList',
            component: () => import('@/views/c-wms/consumable/Consumable'),
            meta: { title: '私有仓库耗材', permission: ['wms_consumable']}
          },
          {
            path: '/zb/wms/goods',
            name: 'WmsGoodsList',
            component: () => import('@/views/c-wms/goods/Goods'),
            meta: { title: '货品', permission: ['wms_goods']}
          },
          {
            path: '/zb/wms/goods/:id',
            name: 'WmsGoodsListEdit',
            hidden: true,
            component: () => import('@/views/c-wms/goods/table/Edit'),
            meta: { title: '货品编辑', permission: ['wms_goods']}
          },
          {
            path: 'commodity',
            name: 'WmsCommodityList',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/Commodity'),
            meta: { title: '商品', permission: ['wms_commodity']}
          },
          {
            path: 'commodity/:id',
            name: 'wms_commodity_by_goods_list',
            hidden: true,
            component: () => import('@/views/c-wms/commodity/table/CommodityByGoodsIdList'),
            meta: { title: '商品', permission: ['wms_commodity']}
          },
          {
            path: 'commodity_purchase/goods/:commodity_id?',
            name: 'WmsCommodityPurchaseList',
            component: () => import('@/views/c-wms/commodity_purchase/CommodityPurchase'),
            meta: { title: '商品入库', permission: ['wms_commodity_purchase']}
          },
          {
            path: 'picks',
            name: 'PicksList',
            component: () => import('@/views/c-wms/pick/Picks'),
            meta: { title: '私有仓库拣货单', permission: ['wms_picks']}
          },
          {
            path: 'config',
            component: () => import('@/views/c-wms/config/Config.vue'),
            name: 'commonStoreConfig',
            meta: {
              title: '配置'
            }
          }
        ]
      },
      {
        path: '/logistics/config',
        component: PageView,
        redirect: '/logistics/config/base',
        name: 'logistics',
        meta: { title: '物流配置', icon: 'car', permission: ['logistics_config'] },
        children: [
          {
            path: '/logistics/config/base',
            name: 'logistics',
            component: () => import('@/views/logistics-config/Logistics'),
            meta: { title: '配置', keepAlive: true }
          }
        ]
      },
      // account
      {
        path: '/account/settings',
        component: PageView,
        redirect: '/account/settings/base',
        hidden: true,
        name: 'account',
        meta: { title: '个人页', icon: 'user', keepAlive: true, permission: [ 'user_settings' ] },
        children: [
          {
            path: '/account/settings/base',
            name: 'settings',
            component: () => import('@/views/account/settings/BaseSetting'),
            meta: { title: '个人设置', hideHeader: true, permission: [ 'user_settings' ] }
          }
        ]
      },
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },
  {
    path: '/404',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]

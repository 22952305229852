//  首先将 弹框组件引入进来
import CustomImportFileForm from './CustomImportFileForm'
//  需要将组件暴露出去
export default {
  //  Vue.js 的插件应该有一个公开方法 install。这个方法的第一个参数是 Vue 构造器，第二个参数是一个可选的选项对象  说白了 就是需要一个install方法 最终 vue通过这个方法将组件安装上
  install: function (Vue) {
    //  定义一个全局组件
    Vue.component('CustomImportFileForm', CustomImportFileForm)
  }
}

import { IsPublicWarehouse } from '@/store/mutation-types'

const corporationConfig = {
  state: {
    IsPublicWarehouse: false
  },
  mutations: {
    [IsPublicWarehouse]: (state, IsPublicWarehouse) => {
      console.log(IsPublicWarehouse, '我有没有设置好')
      state.IsPublicWarehouse = IsPublicWarehouse
    }
  },
  actions: {

  }
}
export default corporationConfig

/* eslint-disable */
export const printANSI = () => {
  // console.clear()
  console.log('')
  // ASCII - ANSI Shadow
  let text = `                   
                       _oo0oo_
                      o8888888o
                      88" . "88
                      (| -_- |)
                      0\\  =  /0
                    ___/\`---'\\___
                  .' \\\\|     |// '.
                 / \\\\|||  :  |||// \\
                / _||||| -:- |||||- \\
               |   | \\\\\\  -  /// |   |
               | \\_|  ''\\---/''  |_/ |
               \\  .-\\__  '-'  ___/-. /
             ___'. .'  /--.--\\  \`. .'___
          ."" '<  \`.___\\_<|>_/___.' >' "".
         \\  \\ \`_.   \\_ __\\ /__ _/   .-\` /  /
     =====\`-.____\`.___ \\_____/___.-\`___.-'=====
                       \`=---='


     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

               佛祖保佑         永无BUG
              Build date: ${BUILD_DATE}`
console.log(`%c${text}`, 'color: #fc4d50')
}

<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :footer="null"
    :confirmLoading="loading"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-row :gutter="[16,16]">
          <a-col :span="18" :offset="3">
            <a-form-item label="文件模板下载">
              <a @click="normal_commodity_template_list">点击下载</a>
            </a-form-item>
          </a-col>
          <a-col :span="18" :offset="3">
            <a-form-item label="文件上传">
              <div>
                <a-upload accept=".xls" :file-list="fileList" :remove="handleRemove" :before-upload="beforeUpload">
                  <a-button> <a-icon type="upload" /> 选择文件 </a-button>
                </a-upload>
                <a-button
                  type="primary"
                  :disabled="fileList.length === 0"
                  :loading="uploading"
                  style="margin-top: 16px"
                  @click="handleUpload"
                >
                  {{ uploading ? '上传中' : '开始上传' }}
                </a-button>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'
import request from '@/utils/request'
import { Dictionaries } from '@/common/AllConstant'
// 表单字段
const fields = ['id', 'cost', 'serial', 'tag_price', 'sales_price']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      uploading: false,
      fileList: [],
      form: this.$form.createForm(this)
    }
  },
  created () {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })
  },
  mounted () {
  },
  methods: {
    /**
     * 上传模版
     */
    normal_commodity_template_list () {
      return request({
        url: this.model.getModelUrl,
        method: 'get',
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        responseType: 'blob'
      }).then((res) => {
        console.log(res)
        const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/octet-stream' })
        link.style.display = 'none'
        link.href = URL.createObjectURL(blob)
        link.setAttribute('download', `模版.xls`)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
    },
    handleRemove (file) {
      const index = this.fileList.indexOf(file)
      const newFileList = this.fileList.slice()
      newFileList.splice(index, 1)
      this.fileList = newFileList
    },
    beforeUpload (file) {
      this.fileList = [...this.fileList, file]
      return false
    },
    handleUpload () {
      const { fileList } = this
      const formData = new FormData()
      fileList.forEach(file => {
        formData.append('file', file)
        formData.append('corporation_id', Dictionaries.corporation_id)
      })
      this.uploading = true
      request({
        url: this.model.uploadurl,
        method: 'post',
        processData: false,
        data: formData
      }).then((response) => {
        this.fileList = []
        this.uploading = false
        this.$message.success('上传成功')
      })
      .catch((error) => {
        console.log(error)
        this.uploading = false
        this.$message.error('上传失败')
      })
    }
  }
}
</script>

import request from '@/utils/request'

const userApi = {
  corporation_list: '/corporation/',
  corporation_create: '/corporation/',
  corporation_update: '/corporation/',
  corporation_delete: '/corporation/',
  corporation_tag_create: '/corporation/tag/',
  corporation_tag_delete: '/corporation/tag/',
  corporation_config_list: '/corporation/',
  corporation_config_partial_update: '/corporation/'
}

/**
 * 企业列表
 */
export function corporation_list (parameter) {
  return request({
    url: userApi.corporation_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加企业
 */
export function corporation_create (parameter) {
  return request({
    url: userApi.corporation_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改企业
 */
export function corporation_update (parameter, corporation_id) {
  return request({
    url: userApi.corporation_update + corporation_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除企业
 */
export function corporation_delete (corporation_id) {
  return request({
    url: userApi.corporation_delete + corporation_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 获取企业配置
 */
export function corporation_config_list (corporation_id) {
  return request({
    url: userApi.corporation_config_list + corporation_id + '/config/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 修改企业配置
 */
export function corporation_config_partial_update (corporation_id) {
  return request({
    url: userApi.corporation_config_partial_update + corporation_id + '/config/',
    method: 'patch',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * 增加标签关联
 */
export function corporation_tag_create (parameter) {
  return request({
    url: userApi.corporation_tag_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除标签关联企业
 */
export function corporation_tag_delete (parameter) {
  return request({
    url: userApi.corporation_tag_delete,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

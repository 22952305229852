import request from '@/utils/request'

const userApi = {
  user_list: '/user/',
  user_create: '/user/',
  user_update: '/user/',
  user_delete: '/user/',
  user_reset_password_update: '/user/',
  user_change_password_update: '/user/',
  user_permission_list: '/user/permission/',
  user_search: '/user_search/'
}

export function user_search (parameter) {
  return request({
    url: userApi.user_search,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 列表
 */
export function user_list (parameter) {
  return request({
    url: userApi.user_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
export function user_permission_list (parameter) {
  return request({
    url: userApi.user_permission_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 重置用户密码
 */
export function user_reset_password_update (parameter, user_id) {
  return request({
    url: userApi.user_reset_password_update + user_id + '/reset_password/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 更改用户密码
 */
export function user_change_password_update (parameter, user_id) {
  return request({
    url: userApi.user_change_password_update + user_id + '/change_password/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 添加
 */
export function user_create (parameter) {
  return request({
    url: userApi.user_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function user_update (parameter, user_id) {
  return request({
    url: userApi.user_update + user_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function user_delete (user_id) {
  return request({
    url: userApi.user_delete + user_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { Dictionaries } from './common/AllConstant'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import axios from 'axios'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import themePluginConfig from '../config/themePluginConfig'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import '@/wx_styles/index.scss' // global css
import './global.less'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import carPlateKeyboard2 from './components/car-plate-keyboard'
import 'car-plate-keyboard/lib/carPlateKeyboard.css'
import { FormModel } from 'ant-design-vue'
import CustomImportFileForm from './components/CustomImportFile'
Vue.use(FormModel)
Vue.use(CustomImportFileForm)
// 这里使用本地的自定义车牌插件 但是css是 npm包引用的
Vue.use(carPlateKeyboard2)
Vue.use(VXETable)
Vue.prototype.$Dictionaries = Dictionaries // global style
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)

window.umi_plugin_ant_themeVar = themePluginConfig.theme

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
